




































































































































































































































































::v-deep {
  .file_uploaded .ico_pdf {
    background-position: 0 -180px;
  }
  .pdfviewer-error {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    font-size: 24px;
    &__btn {
      margin-top: 15px;
      font-size: 18px;
      & > .icon-realod {
        vertical-align: -4px;
      }
    }
  }
}
